export enum SizesIcon {
  xxl = 40,
  xl = 38,
  l = 30,
  m = 28,
  s = 20,
  xs = 16,
  xxs = 12,
}

export type ColorIconType =
  | 'accent'
  | 'primary'
  | 'repeat'
  | 'berry'
  | 'dark'
  | 'snow'
  | 'facebook'
  | 'google'
  | 'error'
  | 'segment'
  | 'none';
export enum ColorIcon {
  accent = 'accent',
  primary = 'primary',
  repeat = 'repeat',
  berry = 'berry',
  dark = 'dark',
  snow = 'snow',
  facebook = 'facebook',
  google = 'google',
  error = 'error',
  none = 'none',
}
