import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { ImageSize } from '../interfaces/sizes.interface';
import { ColorIcon, ColorIconType, SizesIcon } from './icon.enum';

@Component({
  selector: 'eng-dynamic-icon',
  template: `
    <svg
      pointer-events="all"
      [ngClass]="classColor"
      [style.width.px]="getSize()"
      [style.height.px]="getSize()"
    >
      <use pointer-events="all" [attr.xlink:href]="src"></use>
    </svg>
  `,
  styleUrls: ['./dynamic-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicIconComponent {
  @Input() name = '';
  @Input() sprite = 'sprite';
  @Input() size!: ImageSize;

  @Input() color: ColorIconType = ColorIcon.accent;
  @Input() stroke: ColorIconType = ColorIcon.none;

  get src(): string {
    return `/assets/sprites/${this.sprite}.svg#${this.name}`;
  }

  get classColor(): string {
    let classTemp = '';
    if (this.color) {
      classTemp += 'color-' + this.color;
    }
    if (this.stroke) {
      classTemp += ' stroke-' + this.stroke;
    }
    return classTemp;
  }

  constructor(private elementRef: ElementRef) {}

  getSize(): number {
    if (this.size) {
      return SizesIcon[this.size];
    }
    return SizesIcon.m;
  }
}
